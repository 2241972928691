import {
  TextAlignCenter,
  TextAlignLeft,
  TextAlignJustify,
  TextAlignRight,
  TextB,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from '@phosphor-icons/react/dist/ssr';

export const MARKS = [
  { type: 'bold', toggleKeyword: 'toggleBold', icon: TextB },
  { type: 'italic', toggleKeyword: 'toggleItalic', icon: TextItalic },
  { type: 'underline', toggleKeyword: 'toggleUnderline', icon: TextUnderline },
  { type: 'strike', toggleKeyword: 'toggleStrike', icon: TextStrikethrough },
] as const;

export const NODES = [
  { type: 'paragraph', label: 'Text' },
  { type: 'h1', label: 'Heading 1' },
  { type: 'h2', label: 'Heading 2' },
  { type: 'h3', label: 'Heading 3' },
  { type: 'bulletList', label: 'Bulleted list' },
  { type: 'orderedList', label: 'Numbered list' },
  { type: 'blockquote', label: 'Quote' },
];

export const TEXT_ALIGNS = [
  { type: 'left', icon: TextAlignLeft },
  { type: 'center', icon: TextAlignCenter },
  { type: 'right', icon: TextAlignRight },
  { type: 'justify', icon: TextAlignJustify },
];

type NodeLabels = { [K in (typeof NODES)[number]['type']]: (typeof NODES)[number]['label'] };

export type NodeType = (typeof NODES)[number]['type'];

export const NodesTypeLabelEnum: NodeLabels = NODES.reduce((acc, node) => {
  acc[node.type] = node.label;

  return acc;
}, {} as NodeLabels);

export const NodeTypeEnum: { [key in NodeType]: key } = NODES.reduce(
  (acc, node) => {
    acc[node.type] = node.type;

    return acc;
  },
  {} as { [key in NodeType]: key },
);
