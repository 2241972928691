import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import {
  Article,
  ArticleDetail,
  ContentGallery,
  ContentImage,
  ContentQuote,
  ContentRichText,
} from '@/models/article';
import {
  GetArticleBySlugApiResponse,
  GetArticlesApiResponse,
  GetArticleSavedListsApiResponse,
} from '@/types/apis/article';
import { CONTENT_PREFIX } from '@/utils/article-renderer';
import { SavedList } from '@/models/saved-list';

const SERVICE_PATH = '/articles';

export const ARTICLE_SLUGS = [
  { name: 'News', slug: 'news' },
  { name: 'Guide', slug: 'guides' },
  { name: 'Review', slug: 'reviews' },
  { name: 'E-Magazine', slug: 'e-magazines' },
];

const parseToArticle = (res: GetArticlesApiResponse) => {
  const articles = res.data.map((article) => ({
    ...article,

    createdAt: new Date(article.createdAt),
    updatedAt: new Date(article.updatedAt),
    publishedAt: new Date(article.publishedAt),
    ...(article.author
      ? {
          author: {
            ...article.author,
            ...(article.author.avatar
              ? {
                  avatar: {
                    ...article.author.avatar,
                    createdAt: new Date(article.author.avatar.createdAt),
                    updatedAt: new Date(article.author.avatar.updatedAt),
                  },
                }
              : {}),
            createdAt: new Date(article.author.createdAt),
            updatedAt: new Date(article.author.updatedAt),
          },
        }
      : {}),

    ...(article.type
      ? {
          type: {
            ...article.type,
            slug: ARTICLE_SLUGS.find((slug) => slug.name === article.type.name)?.slug ?? null,
            createdAt: new Date(article.type.createdAt),
            updatedAt: new Date(article.type.updatedAt),
            publishedAt: new Date(article.type.publishedAt),
          },
        }
      : {}),

    ...(article.cover
      ? {
          cover: {
            ...article.cover,
            createdAt: new Date(article.cover.createdAt),
            updatedAt: new Date(article.cover.updatedAt),
          },
        }
      : {}),
  })) as Article[];

  return articles;
};

export const getArticles = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data: res } = await publicApi<GetArticlesApiResponse>({
      method: 'GET',
      url: SERVICE_PATH,
      params,
    });

    return parseToArticle(res);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticles` API Error', error);

    return null;
  }
};

export const getArticlesByType = async ({
  type,
  params,
}: {
  type: string;
  params?: AxiosRequestConfig['params'];
}) => {
  try {
    const { data: res } = await publicApi<GetArticlesApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${type}`,
      params,
    });

    return parseToArticle(res);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticlesByType` API Error', error);

    return null;
  }
};

export const getArticleBySlug = async (slug: string) => {
  try {
    const { data: res } = await publicApi<GetArticleBySlugApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${slug}`,
    });

    const { data: queryArticle } = res;

    const article = {
      ...queryArticle,
      createdAt: new Date(queryArticle.createdAt),
      updatedAt: new Date(queryArticle.updatedAt),
      publishedAt: new Date(queryArticle.publishedAt),
      ...(queryArticle.author
        ? {
            author: {
              ...queryArticle.author,
              ...(queryArticle.author.avatar
                ? {
                    avatar: {
                      ...queryArticle.author.avatar,
                      createdAt: new Date(queryArticle.author.avatar.createdAt),
                      updatedAt: new Date(queryArticle.author.avatar.updatedAt),
                    },
                  }
                : {}),
              createdAt: new Date(queryArticle.author.createdAt),
              updatedAt: new Date(queryArticle.author.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.type
        ? {
            type: {
              ...queryArticle.type,
              slug:
                ARTICLE_SLUGS.find((slug) => slug.name === queryArticle.type.name)?.slug ?? null,
              createdAt: new Date(queryArticle.type.createdAt),
              updatedAt: new Date(queryArticle.type.updatedAt),
              publishedAt: new Date(queryArticle.type.publishedAt),
            },
          }
        : {}),

      ...(queryArticle.cover
        ? {
            cover: {
              ...queryArticle.cover,
              createdAt: new Date(queryArticle.cover.createdAt),
              updatedAt: new Date(queryArticle.cover.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.collection
        ? {
            collection: {
              ...queryArticle.collection,
              createdAt: new Date(queryArticle.collection.createdAt),
              updatedAt: new Date(queryArticle.collection.updatedAt),
              publishedAt: new Date(queryArticle.collection.publishedAt),

              reports: queryArticle.collection.reports.map((report) => ({
                ...report,
                createdAt: new Date(report.createdAt),
              })),
            },
          }
        : {}),

      content: queryArticle.content?.map((content) => {
        const { id, __component, tab } = content;

        switch (__component) {
          case `${CONTENT_PREFIX}.rich-text`:
            return {
              id,
              __component,
              tab,
              content: (content as any).content,
            } as ContentRichText;

          case `${CONTENT_PREFIX}.quote`:
            return {
              id,
              __component,
              tab,
              quote: (content as any).quote,
              quoter: (content as any).quoter,
            } as ContentQuote;

          case `${CONTENT_PREFIX}.image`:
            return {
              id,
              __component,
              tab,
              caption: (content as any).caption,
              fullWidth: (content as any).fullWidth,
              image: {
                ...(content as any).image,
                createdAt: new Date((content as any).image.createdAt),
                updatedAt: new Date((content as any).image.updatedAt),
              },
            } as ContentImage;

          case `${CONTENT_PREFIX}.gallery`:
            return {
              id,
              __component,
              tab,
              caption: (content as any).caption,
              fullWidth: (content as any).fullWidth,
              images: (content as any).images.map((image: any) => ({
                ...image,
                createdAt: new Date(image.createdAt),
                updatedAt: new Date(image.updatedAt),
              })),
            } as ContentGallery;

          default:
            return content;
        }
      }),
    } as ArticleDetail;

    return article;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticleBySlug` API Error', error);

    return null;
  }
};

export const getArticleSavedLists = async (articleId: Article['id'], token: string) => {
  try {
    const { data } = await publicApi<GetArticleSavedListsApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${articleId}/saved-lists`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.map(
      (savedList) =>
        ({
          ...savedList,
          createdAt: new Date(savedList.createdAt),
          updatedAt: new Date(savedList.updatedAt),
        }) as SavedList,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticleSavedLists` API Error', error);

    return null;
  }
};
