import { Button } from '@nextui-org/button';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import { CaretDown } from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';
import { Editor } from '@tiptap/react';

import { NODES, NodeTypeEnum, NodeType, NodesTypeLabelEnum } from './EditorMenus.config';

interface NodesDropdownProps {
  editor: Editor;
}

const NodesDropdown: React.FC<NodesDropdownProps> = ({ editor }) => {
  let currentNode: NodeType | undefined = undefined;

  if (!editor.isActive('figure')) {
    if (editor.isActive(NodeTypeEnum.blockquote)) {
      currentNode = NodeTypeEnum.blockquote;
    } else if (editor.isActive('heading')) {
      const headingLevel = {
        1: NodeTypeEnum.h1,
        2: NodeTypeEnum.h2,
        3: NodeTypeEnum.h3,
      } as const;

      const selectedLevel = editor.state.selection.$head.parent.attrs.level as 1 | 2 | 3;

      currentNode = headingLevel[selectedLevel] || undefined;
    } else if (editor.isActive(NodeTypeEnum.bulletList)) {
      currentNode = NodeTypeEnum.bulletList;
    } else if (editor.isActive(NodeTypeEnum.orderedList)) {
      currentNode = NodeTypeEnum.orderedList;
    } else if (editor.isActive(NodeTypeEnum.paragraph)) {
      currentNode = NodeTypeEnum.paragraph;
    }
  }

  const handleClick = (type: NodeType) => {
    const { commands } = editor;

    switch (type) {
      case NodeTypeEnum.paragraph:
        commands.setParagraph();
        break;

      case NodeTypeEnum.h1:
        commands.setHeading({ level: 1 });
        break;

      case NodeTypeEnum.h2:
        commands.setHeading({ level: 2 });
        break;

      case NodeTypeEnum.h3:
        commands.setHeading({ level: 3 });
        break;

      case NodeTypeEnum.bulletList:
        commands.toggleBulletList();
        break;

      case NodeTypeEnum.orderedList:
        commands.toggleOrderedList();
        break;

      case NodeTypeEnum.blockquote:
        commands.setBlockquote();
        break;

      default:
        break;
    }
  };

  return (
    <Dropdown
      classNames={{
        content: 'border-none bg-background rounded-none',
      }}
      offset={10}
      placement='bottom-end'
      radius='sm'
      ria-label='Saved list actions'
    >
      <DropdownTrigger>
        <Button
          className='p-2'
          endContent={<CaretDown className='text-gray-600' size={16} />}
          radius='none'
          variant='light'
        >
          {NodesTypeLabelEnum[currentNode!]}
        </Button>
      </DropdownTrigger>

      <DropdownMenu
        selectedKeys={[currentNode!]}
        selectionMode='single'
        onSelectionChange={(key) => handleClick((key as Set<NodeType>).values().next().value!)}
      >
        {NODES.map(({ type, label }) => (
          <DropdownItem key={type} showDivider variant='light'>
            {label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NodesDropdown;
