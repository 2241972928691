import { Extensions, mergeAttributes } from '@tiptap/core';
// Nodes
import Text from '@tiptap/extension-text';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Blockquote from '@tiptap/extension-blockquote';
// Marks
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Link from '@tiptap/extension-link';
// Functionality
import History from '@tiptap/extension-history';
import Dropcursor from '@tiptap/extension-dropcursor';
import TextAlign from '@tiptap/extension-text-align';
import { cn } from '@nextui-org/theme';

import Document from './Document';
import Paragraph from './Paragraph';

import { heading } from '@/theme/typography';

export const StarterKit: Extensions = [
  // Nodes
  Document,
  Text,
  Paragraph,
  Heading.extend({
    levels: [1, 2, 3, 4, 5, 6],
    renderHTML({ node, HTMLAttributes }) {
      const headingClasses = {
        1: heading({ size: 'h1', weight: 'medium' }),
        2: heading({ size: 'h2', weight: 'medium' }),
        3: heading({ size: 'h3', weight: 'medium' }),
        4: heading({ size: 'h4', weight: 'medium' }),
        5: heading({ size: 'h5', weight: 'medium' }),
        6: heading({ size: 'h6', weight: 'medium' }),
      };

      type Level = keyof typeof headingClasses;

      const level = (
        this.options.levels.includes(node.attrs.level) ? node.attrs.level : this.options.levels[0]
      ) as Level;

      return [
        `h${level}`,
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          class: cn(headingClasses[level], '!m-0 py-3'),
          level,
        }),
        0,
      ];
    },
  }),
  BulletList.configure({
    HTMLAttributes: {
      class: 'list-disc list-outside leading-3 pl-6',
    },
  }),
  OrderedList.configure({
    HTMLAttributes: {
      class: 'list-decimal list-outside leading-3 pl-6',
    },
  }),
  ListItem.configure({
    HTMLAttributes: {
      class: 'leading-normal',
    },
  }),
  Blockquote.configure({
    HTMLAttributes: {
      class: 'border-l-4 border-gray-300 pl-4',
    },
  }),

  // -------------------------

  // Marks
  Bold,
  Italic,
  Underline,
  Strike,
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class:
        '!underline text-slate-500 dark:!text-slate-400 [&>*]:!text-slate-500 dark:[&>*]:!text-slate-400',
    },
    validate: (href) => /^https?:\/\//.test(href),
  }),

  // -------------------------

  // Functionality
  History.configure({ depth: 20 }),
  Dropcursor.configure({
    color: '#1781EC',
    width: 2,
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
];
