'use client';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '@nextui-org/modal';
import { cn } from '@nextui-org/theme';
import React, { useEffect } from 'react';
import { Button } from '@nextui-org/button';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Avatar } from '@nextui-org/avatar';
import { Plus, UploadSimple, X } from '@phosphor-icons/react/dist/ssr';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { body, heading } from '@/theme/typography';
import { RHFFile, RHFInput, RHFSelect, RHFTextarea } from '@/components';
import { useUserProfile } from '@/hooks/queries';
import { EditUserProfileSchema } from '@/validations/user';
import { useUserProfileMutation } from '@/hooks/mutations';
import { UpdateUserProfileApiRequest } from '@/types/apis/user';
import { SOCIAL_LINK_TYPES } from '@/config/user';

interface EditProfileModalProps extends Omit<ModalProps, 'children'> {
  username: string;
}

interface UpdateProfileFormAttributes {
  name: string;
  username: string;
  drive?: string | null;
  gender?: string | null;
  location?: string | null;
  bio?: string | null;
  links?: {
    type: string;
    url: string;
  }[];
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  username,
  className,
  classNames,
  ...others
}) => {
  const { updateMutation } = useUserProfileMutation();
  const { user, isLoading } = useUserProfile(username);

  const [avatarUrl, setAvatarUrl] = React.useState<string>();
  const [avatarFile, setAvatarFile] = React.useState<File | null>();

  const method = useForm<UpdateProfileFormAttributes>({
    defaultValues: {
      links: [{ type: '', url: '' }],
    },
    disabled: isLoading || updateMutation.isPending,
    resolver: yupResolver(EditUserProfileSchema),
  });

  const { fields, append, remove } = useFieldArray<UpdateProfileFormAttributes>({
    control: method.control,
    name: 'links',
  });

  useEffect(() => {
    if (user) {
      method.reset({
        name: user.profile.name,
        username: user.profile.username,
        drive: user.profile.drive,
        location: user.profile.location,
        bio: user.profile.bio,
        gender: user.profile.gender,
        links: user.socialLinks.map(({ platform, url }) => ({
          type: platform,
          url,
        })),
      });
      setAvatarUrl(user.profile.avatar?.url);
    }
  }, [user]);

  const handleCloseModal = () => {
    setAvatarUrl(user?.profile.avatar?.url);
    method.reset();

    others.onClose?.();
  };

  const handleSubmit: SubmitHandler<UpdateProfileFormAttributes> = async (formData) => {
    const data = {
      avatar: avatarFile,
      name: formData.name,
      username: formData.username,
      drive: formData.drive,
      gender: formData.gender,
      location: formData.location,
      bio: formData.bio,
      socialLinks:
        formData.links?.map(({ type, url }) => ({
          platform: type,
          url,
        })) ?? [],
    } as UpdateUserProfileApiRequest;

    await updateMutation.mutateAsync(data, {
      onSuccess: () => {
        toast('Profile updated successfully');
        handleCloseModal();
      },
      onError: () => {
        toast.error('Profile update failed');
      },
    });
  };

  const handleAvatarFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length <= 0) return;

    setAvatarFile(event.target.files[0]);
    setAvatarUrl(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <Modal
      shouldBlockScroll
      isDismissable={false}
      isKeyboardDismissDisabled={false}
      radius='none'
      scrollBehavior='inside'
      {...others}
      className={cn('max-h-screen', className)}
      classNames={{
        base: 'max-w-lg !max-h-[85vh]',
        closeButton: 'top-4 right-4 text-[24px] rounded-none',
        ...classNames,
      }}
      onClose={handleCloseModal}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader
              className={cn(
                heading({ size: 'h6', weight: 'medium' }),
                'self-center p-6 text-center',
              )}
            >
              Profile information
            </ModalHeader>

            <ModalBody className='gap-8'>
              <FormProvider {...method}>
                <div className='flex flex-col gap-2'>
                  <label className={cn(body({ weight: 'medium' }))} htmlFor='avatar'>
                    Photo
                  </label>

                  <div className='flex flex-row gap-6'>
                    <Avatar className='h-20 w-20 shrink-0' src={avatarUrl} />

                    <div className='flex flex-col items-start gap-2'>
                      <div className='flex flex-row gap-2'>
                        <Button
                          as='label'
                          className='p-0 hover:bg-transparent'
                          color='primary'
                          disabled={updateMutation.isPending}
                          htmlFor='avatar'
                          radius='none'
                          startContent={<UploadSimple size={20} />}
                          variant='light'
                        >
                          <RHFFile
                            accept='image/*'
                            name='avatar'
                            onChange={handleAvatarFileChange}
                          />
                          Upload
                        </Button>

                        {avatarUrl && (
                          <Button
                            className='p-0 hover:bg-transparent'
                            color='danger'
                            disabled={updateMutation.isPending}
                            radius='none'
                            variant='light'
                            onClick={() => {
                              setAvatarUrl(undefined);
                              setAvatarFile(null);
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                      <p className={cn(body(), 'text-gray-500')}>
                        Recommended: Square JPG, PNG, at least 1,000 pixels per side
                      </p>
                    </div>
                  </div>
                </div>

                <RHFInput
                  isRequired
                  label='Name'
                  name='name'
                  placeholder='Enter your name'
                  size='lg'
                />

                <RHFInput
                  isRequired
                  label='Username'
                  name='username'
                  placeholder='Enter your name'
                  size='lg'
                />

                <RHFSelect
                  items={[
                    { key: 'male', label: 'Male' },
                    { key: 'female', label: 'Female' },
                    { key: 'other', label: 'Other' },
                  ]}
                  label='Gender'
                  name='gender'
                  placeholder='Select'
                  size='lg'
                />

                <RHFInput
                  label='Drive'
                  name='drive'
                  placeholder='Tell us about your drive'
                  size='lg'
                />

                <RHFInput
                  label='Location'
                  name='location'
                  placeholder='Where are you from?'
                  size='lg'
                />

                <RHFTextarea
                  label='Short bio'
                  minRows={6}
                  name='bio'
                  placeholder='Tell us about yourself'
                  size='lg'
                />

                <fieldset className='flex flex-col gap-2'>
                  <label className={cn(body({ weight: 'medium' }))} htmlFor='links'>
                    Links
                  </label>

                  <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-2'>
                      {fields.map(({ id }, index) => (
                        <div key={id} className='flex flex-row items-start gap-2'>
                          <RHFSelect
                            aria-label='Select social link type'
                            className='w-1/3'
                            items={SOCIAL_LINK_TYPES}
                            labelPlacement='inside'
                            name={`links.${index}.type`}
                            placeholder='Select'
                            size='lg'
                          />
                          <RHFInput
                            aria-label='Enter social link URL'
                            className='w-2/3'
                            labelPlacement='inside'
                            name={`links.${index}.url`}
                            placeholder='Enter the URL'
                            size='lg'
                          />
                          <Button
                            isIconOnly
                            radius='none'
                            size='lg'
                            variant='light'
                            onClick={() => remove(index)}
                          >
                            <X size={24} />
                          </Button>
                        </div>
                      ))}
                    </div>

                    <button
                      className='flex flex-row items-center gap-2'
                      onClick={() => append({ type: '', url: '' })}
                    >
                      <div className='flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-100'>
                        <Plus size={20} />
                      </div>
                      Add social link
                    </button>
                  </div>
                </fieldset>
              </FormProvider>
            </ModalBody>

            <ModalFooter className='gap-4 p-6'>
              <Button
                className='border-gray-50'
                disabled={updateMutation.isPending}
                radius='none'
                size='lg'
                variant='bordered'
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                isLoading={updateMutation.isPending}
                radius='none'
                size='lg'
                onClick={method.handleSubmit(handleSubmit)}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default EditProfileModal;
