'use client';

import { Avatar } from '@nextui-org/avatar';
import React from 'react';
import { formatDate } from 'date-fns';
import { cn } from '@nextui-org/theme';
import Link from 'next/link';

import { User } from '@/models/user';
import { body, caption } from '@/theme/typography';

interface ArticleAuthorProps {
  author: User;
  createdAt: Date;
  className?: string;
}

const ArticleAuthor: React.FC<ArticleAuthorProps> = ({ author, createdAt, className }) => {
  const [isFollowing, setIsFollowing] = React.useState(false);

  const handleToggleFollow = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsFollowing((prev) => !prev);
  };

  return (
    <div className={cn('flex flex-row items-center gap-2', className)}>
      <Avatar
        as={Link}
        className='h-12 w-12'
        href={`/${author.username}`}
        src={author.avatar?.url}
      />
      <div className='flex flex-col'>
        <div className='flex flex-row items-center gap-x-0.5'>
          <Link
            className={cn(body({ weight: 'medium' }), 'hover:underline')}
            href={`/${author.username}`}
          >
            {author.name}
          </Link>
          <p className='text-gray-400'>・</p>
          <button
            className={cn(body({ weight: 'medium' }), {
              'text-gray-500': isFollowing,
              'text-blue-500': !isFollowing,
            })}
            onClick={handleToggleFollow}
          >
            {isFollowing ? 'Following' : 'Follow'}
          </button>
        </div>

        <p className={cn(caption(), 'text-gray-500')}>{formatDate(createdAt, 'd MMM yyyy')}</p>
      </div>
    </div>
  );
};

export default ArticleAuthor;
