'use client';
import * as React from 'react';
import { cn } from '@nextui-org/theme';
import { Button } from '@nextui-org/button';
import { useRouter } from 'next/navigation';

import FallbackImage from '../Images/FallbackImage/FallbackImage';

import EmptyPng from '@/assets/images/empty-state.png';
import { body, heading } from '@/theme/typography';

interface EmptyProps {
  message?: React.ReactNode;
  className?: string;
  title?: string;
  buttonTitle?: string;
}

const Empty: React.FC<EmptyProps> = ({ message, className, title, buttonTitle }) => {
  const router = useRouter();

  const handleGoBack = () => {
    router.back();
  };

  return (
    <div className={cn('flex flex-col items-center gap-6 self-center text-center', className)}>
      <FallbackImage
        alt='Not found'
        className='h-[300px] w-[300px] rounded-full bg-gray-100'
        height={300}
        src={EmptyPng}
        width={300}
      />

      <h5 className={cn(heading({ size: 'h5' }), 'mb-[-1rem]')}>{title}</h5>

      <div className={body({ weight: 'medium' })}>
        {message ?? (
          <>
            Sorry, we couldn&apos;t find anything matching your search.
            <br />
            Please try again.
          </>
        )}
      </div>

      {buttonTitle && (
        <Button
          className='rounded-none border border-[#171C22] px-6 py-4'
          size='lg'
          variant='bordered'
          onClick={handleGoBack}
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};

export default Empty;
