'use client';

import React, { useRef } from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import { Divider } from '@nextui-org/divider';
import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import 'tippy.js/animations/scale-subtle.css';

import NodesDropdown from './NodesDropdown';
import { MARKS, TEXT_ALIGNS } from './EditorMenus.config';

const EditorMenus = ({ editor }: { editor: Editor | null }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  if (!editor) return null;

  return (
    <>
      <BubbleMenu
        className='border-1 border-gray-100 bg-background'
        editor={editor}
        pluginKey='TextMenu'
        tippyOptions={{
          popperOptions: { modifiers: [{ name: 'eventListeners', options: { scroll: true } }] },
          duration: 100,
          animation: 'scale-subtle',
          maxWidth: '100vw',
        }}
      >
        <div ref={containerRef} className='relative z-10 flex shrink-0 flex-row items-center gap-1'>
          <NodesDropdown editor={editor} />

          <Divider className='h-8' orientation='vertical' />

          <div className='flex shrink-0 flex-row items-center'>
            {MARKS.map(({ type, icon: Icon, toggleKeyword }) => (
              <Button
                key={type}
                isIconOnly
                radius='none'
                variant='light'
                onClick={() => editor.chain().focus()[toggleKeyword]().run()}
              >
                <Icon
                  className={cn('pointer-events-none h-5 w-5 text-gray-800', {
                    'text-primary': editor.isActive(type),
                  })}
                  weight='bold'
                />
              </Button>
            ))}
          </div>

          <Divider className='h-8' orientation='vertical' />

          <div className='flex shrink-0 flex-row items-center'>
            {TEXT_ALIGNS.map(({ type, icon: Icon }) => (
              <Button
                key={type}
                isIconOnly
                radius='none'
                variant='light'
                onClick={() => editor.chain().focus().setTextAlign(type).run()}
              >
                <Icon
                  className={cn('pointer-events-none h-5 w-5 text-gray-800', {
                    'text-primary': editor.isActive({ textAlign: type }),
                  })}
                />
              </Button>
            ))}
          </div>
        </div>
      </BubbleMenu>
    </>
  );
};

export default EditorMenus;
