import {
  ListBullets,
  ListNumbers,
  Quotes,
  TextHOne,
  TextHThree,
  TextHTwo,
} from '@phosphor-icons/react/dist/ssr';
import { Range, Editor } from '@tiptap/core';

interface Command {
  editor: Editor;
  range: Range;
}

export const SLASH_NODES = [
  {
    title: 'Text',
    icon: Quotes,
    command: ({ editor, range }: Command) => {
      editor.chain().focus().deleteRange(range).toggleNode('paragraph', 'paragraph').run();
    },
  },
  {
    title: 'Heading 1',
    icon: TextHOne,
    command: ({ editor, range }: Command) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run();
    },
  },
  {
    title: 'Heading 2',
    icon: TextHTwo,
    command: ({ editor, range }: Command) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run();
    },
  },
  {
    title: 'Heading 3',
    icon: TextHThree,
    command: ({ editor, range }: Command) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 3 }).run();
    },
  },
  {
    title: 'Bullet List',
    icon: ListBullets,
    command: ({ editor, range }: Command) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    },
  },
  {
    title: 'Numbered List',
    icon: ListNumbers,
    command: ({ editor, range }: Command) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    },
  },
  {
    title: 'Quote',
    icon: Quotes,
    command: ({ editor, range }: Command) => {
      editor.chain().focus().deleteRange(range).setParagraph().run();
      editor.chain().focus().deleteRange(range).setBlockquote().run();
    },
  },
] as const;
