import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { getArticleSavedLists } from '@/services/apis/article';
import { Article } from '@/models/article';

const useArticleSavedLists = (articleId: Article['id']) => {
  const { data: session } = useSession();

  const { data, ...others } = useQuery({
    queryKey: ['saved-lists', { articleId }],
    queryFn: () => getArticleSavedLists(articleId, session?.token!),
    staleTime: 10 * 1000,
    select: (data) => data,
    enabled: !!articleId && !!session?.token,
  });

  return {
    savedLists: data,
    ...others,
  };
};

export default useArticleSavedLists;
