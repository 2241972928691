'use client';

import React, { Fragment, useMemo, useState } from 'react';
import { cn } from '@nextui-org/theme';

import { Tabs } from '@/components';
import { Content } from '@/models/article';
import { articleRenderer } from '@/utils/article-renderer';

interface ArticleContentProps {
  contents: Content[];
  className?: string;
  classNames?: {
    section: string;
  };
}

const sortContentByTab = (contents: Content[]) => {
  return Object.values(
    contents.reduce(
      (acc: Record<string, { tab: string; contents: Content[] }>, { tab, ...content }) => {
        if (!tab) return acc;
        if (!acc[tab]) acc[tab] = { tab, contents: [] };

        acc[tab].contents.push({ tab, ...content });

        return acc;
      },
      {},
    ),
  );
};

const ArticleContent: React.FC<ArticleContentProps> = ({ contents, className, classNames }) => {
  const sortedContent = useMemo(() => sortContentByTab(contents), [contents]);
  const tabs = useMemo(
    () => sortedContent.map(({ tab }) => ({ key: tab, title: tab })),
    [contents],
  );

  const [currentTabKey, setCurrentTabKey] = useState(tabs[0]?.key);

  const currentTabContent = useMemo(
    () => sortedContent.find(({ tab }) => tab === currentTabKey),
    [sortedContent, currentTabKey],
  );

  return (
    <>
      {tabs.length > 0 && (
        <div className='sticky top-24 z-20 flex w-full items-center justify-center border-b-1 border-b-gray-100 bg-neutral-white'>
          <Tabs
            className='w-full max-w-4xl p-0 capitalize'
            classNames={{ tabList: 'p-0' }}
            items={tabs}
            selectedKey={currentTabKey}
            onSelectionChange={(key) => setCurrentTabKey(key as string)}
          />
        </div>
      )}

      <article className={cn('mt-12 flex max-w-4xl flex-col gap-10 self-center', className)}>
        {tabs.length > 0 &&
          currentTabContent?.contents.map((section) => (
            <Fragment key={section.id}>{articleRenderer(section)}</Fragment>
          ))}

        {tabs.length <= 0 &&
          contents.map((section) => (
            <Fragment key={section.id}>{articleRenderer(section, classNames?.section)}</Fragment>
          ))}
      </article>
    </>
  );
};

export default ArticleContent;
