import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import DragNodeView from './DragNodeView';

export interface DragNodeOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dragNode: {
      /**
       * Toggle a dragNode
       */
      setDragNode: (position?: number) => ReturnType;
    };
  }
}

export const DragNode = Node.create<DragNodeOptions>({
  name: 'dragNode',
  priority: 1000,
  group: 'dragNode',
  content: 'block',
  draggable: true,
  selectable: false,
  inline: false,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: 'div[data-type="d-block"]' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'd-block' }), 0];
  },

  addCommands() {
    return {
      setDragNode:
        (position) =>
        ({ state, chain }) => {
          const {
            selection: { from },
          } = state;

          const pos = position !== undefined || position !== null ? from : position;

          return chain()
            .insertContentAt(pos, {
              type: this.name,
              content: [{ type: 'paragraph' }],
            })
            .focus(pos + 2)
            .run();
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(DragNodeView);
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-0': () => this.editor.commands.setDragNode(),
      Enter: ({ editor }) => {
        const {
          selection: { $head, from, to },
          doc,
        } = editor.state;

        const parent = $head.node($head.depth - 1);

        if (parent.type.name !== 'dragNode') return false;

        let currentActiveNodeTo = -1;

        doc.descendants((node, pos) => {
          if (currentActiveNodeTo !== -1) return false;
          if (node.type.name === this.name) return;

          const [nodeFrom, nodeTo] = [pos, pos + node.nodeSize];

          if (nodeFrom <= from && to <= nodeTo) currentActiveNodeTo = nodeTo;

          return false;
        });

        const content = doc.slice(from, currentActiveNodeTo)?.toJSON().content;

        return editor
          .chain()
          .insertContentAt({ from, to: currentActiveNodeTo }, { type: this.name, content })
          .focus(from + 4)
          .run();
      },
    };
  },
});
