/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { NodeViewWrapper, NodeViewProps, NodeViewContent } from '@tiptap/react';
import { cn } from '@nextui-org/theme';
import { DotsSixVertical, Plus } from '@phosphor-icons/react/dist/ssr';
import { Button } from '@nextui-org/button';

const DragNodeView: React.FC<NodeViewProps> = ({ node, getPos, editor }) => {
  const createNodeAfter = () => {
    const pos = getPos() + node.nodeSize;

    editor.commands.insertContentAt(pos, {
      type: 'dragNode',
      content: [{ type: 'paragraph' }],
    });
  };

  return (
    <NodeViewWrapper as='div' className='group relative w-full gap-2'>
      <div
        aria-label='left-menu'
        className={cn(
          'absolute -top-0.5 left-0 z-10 -translate-x-full pl-32 pr-2',
          'flex flex-row',
        )}
        contentEditable={false}
      >
        <Button
          isIconOnly
          className={cn(
            'flex h-7 w-7 min-w-7 cursor-grab items-center justify-center rounded-sm p-1 text-gray-800',
            'opacity-0 transition duration-200 ease-in-out group-hover:opacity-100 hover:bg-gray-50',
          )}
          size='sm'
          variant='light'
          onClick={createNodeAfter}
        >
          <Plus size={16} />
        </Button>

        <div
          data-drag-handle
          draggable
          className={cn(
            'flex h-7 cursor-grab items-center justify-center rounded-sm p-1 text-gray-800',
            'opacity-0 transition duration-200 ease-in-out group-hover:opacity-100 hover:bg-gray-50',
          )}
          contentEditable={false}
        >
          <DotsSixVertical size={18} weight='bold' />
        </div>
      </div>

      <NodeViewContent className={cn('node-view-content w-full')} />
    </NodeViewWrapper>
  );
};

export default DragNodeView;
