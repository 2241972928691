'use client';

import { Button } from '@nextui-org/button';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import { cn } from '@nextui-org/theme';
import { DotsThree } from '@phosphor-icons/react/dist/ssr';
import React from 'react';
import { toast } from 'react-toastify';
import { TrashSimple } from '@phosphor-icons/react';

import { saveImage } from '@/utils/file';
import useMediaMutation from '@/hooks/mutations/useMediaMutation';
import useConfirm from '@/hooks/useConfirm';

interface MediaThumbnailActionProps {
  id: number;
  imageUrl: string;
}

const MediaThumbnailAction: React.FC<MediaThumbnailActionProps> = ({ id, imageUrl }) => {
  const { confirm } = useConfirm();

  const { deleteMutation } = useMediaMutation();

  const handleDownload = async () => {
    try {
      await saveImage(imageUrl);
    } catch (error) {
      toast.error('Error downloading the image');
    }
  };

  const handleDelete = async () => {
    confirm({
      classNames: { cancelButton: 'border-gray-50' },
      confirmButtonProps: { color: 'danger' },
      confirmText: 'Delete',
      icon: <TrashSimple size={24} />,
      message: 'Are you sure you want to delete this media?',
      title: 'Delete media',
      onConfirm: async () => {
        await deleteMutation.mutateAsync(id, {
          onSuccess: () => {
            toast('Image deleted successfully');
          },
          onError: () => {
            toast.error('Error deleting the image');
          },
        });
      },
    });
  };

  return (
    <>
      <Dropdown
        showArrow
        classNames={{
          content: 'border-none bg-background rounded-none',
        }}
        offset={10}
        placement='bottom-end'
        radius='sm'
        ria-label='Saved list actions'
      >
        <DropdownTrigger>
          <Button
            isIconOnly
            className='absolute right-2 top-2 z-10 bg-gray-900/50 text-neutral-white opacity-0 backdrop-blur-sm group-hover:opacity-100 hover:bg-gray-900/80'
            radius='none'
            size='sm'
            variant='light'
          >
            <DotsThree size={24} weight='bold' />
          </Button>
        </DropdownTrigger>

        <DropdownMenu aria-label='Sort comments'>
          <DropdownItem download showDivider variant='light' onClick={handleDownload}>
            Download
          </DropdownItem>
          <DropdownItem showDivider variant='light'>
            Move to album
          </DropdownItem>
          <DropdownItem
            className={cn('text-danger')}
            color='danger'
            variant='light'
            onClick={handleDelete}
          >
            Delete image
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default MediaThumbnailAction;
