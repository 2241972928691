'use client';

import React, { useEffect, useState } from 'react';
import { useEditor, EditorContent, EditorContentProps, JSONContent } from '@tiptap/react';
import { useDebounce } from '@uidotdev/usehooks';
import { Skeleton } from '@nextui-org/skeleton';

import { TipTapEditorProps } from './lib/editor-props';
import { TipTapExtensions } from './extensions';
import EditorMenus from './EditorMenus/EditorMenus';

type EditorProps = Omit<EditorContentProps, 'ref' | 'editor'>;

const Editor: React.FC<EditorProps> = ({ ...others }) => {
  const [hydrated, setHydrated] = useState<boolean>(false);
  const [content, setContent] = useState<JSONContent | null>(null);
  const debouncedContent = useDebounce(content, 1000);

  const editor = useEditor({
    extensions: TipTapExtensions,
    editorProps: TipTapEditorProps,
    onUpdate: ({ editor }) => setContent(editor.getJSON()),
    immediatelyRender: false,
    content: debouncedContent,
  });

  // Hydrate the editor
  useEffect(() => {
    if (editor && !hydrated) {
      setHydrated(true);
    }
  }, [editor, hydrated]);

  return (
    <div className='h-full w-full cursor-text'>
      {hydrated && (
        <>
          <EditorMenus editor={editor} />
          <EditorContent {...others} editor={editor} />
        </>
      )}

      {!hydrated && (
        <div className='container flex w-full max-w-5xl flex-col px-20'>
          <Skeleton className='my-2 h-5 w-full rounded-full' />
          <Skeleton className='my-2 h-5 w-full rounded-full' />
          <Skeleton className='my-2 h-5 w-3/4 rounded-full' />
        </div>
      )}
    </div>
  );
};

export default Editor;
