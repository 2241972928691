import { publicApi } from '@/config/api';

const SERVICE_PATH = '/upload';

export const upload = async ({ data, token }: { data: FormData; token: string }) => {
  try {
    await publicApi({
      method: 'POST',
      url: SERVICE_PATH,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`upload` API Error', error);
  }
};
