import { Extensions } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';

import { StarterKit } from './starter-kit/StarterKit';
import { DragNode } from './drag-node/DragNode';
import SlashCommand from './slash-command/SlashCommand';

export const TipTapExtensions: Extensions = [
  ...StarterKit,
  DragNode,
  SlashCommand,
  Placeholder.configure({
    placeholder: ({ node, editor }) => {
      if (node.type.name === 'heading') return `Heading ${node.attrs.level}`;

      const {
        selection: { $head },
      } = editor.state;

      const parent = $head.node($head.depth - 1);

      if (parent?.type.name === 'listItem') return 'List';

      return `Tell your story. Type '/' to browse options`;
    },
    includeChildren: true,
  }),
];
