import { AxiosRequestConfig } from 'axios';

import { ARTICLE_SLUGS } from './article';

import { publicApi } from '@/config/api';
import {
  CreateSavedListApiRequest,
  UpdateSavedListApiRequest,
  GetMySavedListsApiResponse,
  GetSavedListByIdApiResponse,
  GetSavedArticlesByListId,
} from '@/types/apis/saved-list';
import { SavedList } from '@/models/saved-list';
import { Article } from '@/models/article';

const SERVICE_PATH = '/saved-lists';

export const getMySavedLists = async (
  token: string,
  { params }: { params?: AxiosRequestConfig['params'] },
) => {
  try {
    const { data: res } = await publicApi<GetMySavedListsApiResponse>({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: SERVICE_PATH,
      params,
    });

    return res.results.map(
      (item) =>
        ({
          ...item,
          previewImages: item.previewImages?.map((image) => ({
            ...image,
            createdAt: new Date(image.createdAt),
            updatedAt: new Date(image.updatedAt),
          })),

          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
        }) as SavedList & {
          totalArticles: number;
        },
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getMySavedLists` API Error', error);

    return null;
  }
};

export const getSavedListById = async (
  id: number,
  token: string,
  { params }: { params?: AxiosRequestConfig['params'] },
) => {
  try {
    const {
      data: { data: res },
    } = await publicApi<GetSavedListByIdApiResponse>({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}`,
      params,
    });

    return {
      ...res,

      createdAt: new Date(res.createdAt),
      updatedAt: new Date(res.updatedAt),
    } as SavedList;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getSavedListById` API Error', error);

    return null;
  }
};

export const createSavedList = async ({
  data,
  token,
}: {
  data: CreateSavedListApiRequest;
  token: string;
}) => {
  try {
    await publicApi({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: SERVICE_PATH,
      data,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`createSavedList` API Error', error);
  }
};

export const updateSavedList = async ({
  id,
  data,
  token,
}: {
  id: number;
  data: UpdateSavedListApiRequest;
  token: string;
}) => {
  try {
    await publicApi({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}`,
      data,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`updateSavedList` API Error', error);
  }
};

export const deleteSavedList = async (id: number, token: string) => {
  try {
    await publicApi({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}`,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`deleteSavedList` API Error', error);
  }
};

export const saveArticleToSavedList = async (id: number, articleId: number, token: string) => {
  try {
    await publicApi({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/add-article/${id}`,
      data: {
        articleId,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`saveArticleToSavedList` API Error', error);
  }
};

export const unsaveArticleFromSavedList = async (id: number, articleId: number, token: string) => {
  try {
    await publicApi({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}/${articleId}`,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`saveArticleToSavedList` API Error', error);
  }
};

export const getSavedArticlesByListId = async (id: number, token: string) => {
  try {
    const { data: res } = await publicApi<GetSavedArticlesByListId>({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}/articles`,
    });

    return {
      ...res,

      articles: res.articles.map(
        (article) =>
          ({
            ...article,

            ...(article.type
              ? {
                  type: {
                    ...article.type,
                    slug:
                      ARTICLE_SLUGS.find((slug) => slug.name === article.type.name)?.slug ?? null,
                    createdAt: new Date(article.type.createdAt),
                    updatedAt: new Date(article.type.updatedAt),
                    publishedAt: new Date(article.type.publishedAt),
                  },
                }
              : {}),

            createdAt: new Date(article.createdAt),
            updatedAt: new Date(article.updatedAt),
          }) as Article,
      ),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getSavedArticlesByListId` API Error', error);

    return null;
  }
};
