import React, { useState, useEffect, useCallback, useRef } from 'react';
import { cn } from '@nextui-org/theme';

import { SLASH_NODES } from '../extensions/slash-command/SlashCommand.config';

import { body } from '@/theme/typography';

type CommandItemProps = (typeof SLASH_NODES)[number];

const CommandList = ({ items, command }: { items: CommandItemProps[]; command: any }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const commandListContainer = useRef<HTMLDivElement>(null);
  const selectedButtonRef = useRef<HTMLButtonElement>(null);

  const handleSelect = useCallback(
    (index: number) => {
      const item = items[index];

      if (item) {
        command(item);
      }
    },
    [command, items],
  );

  useEffect(() => {
    const navigationKeys = ['ArrowUp', 'ArrowDown', 'Enter'];
    const onKeyDown = (e: KeyboardEvent) => {
      if (navigationKeys.includes(e.key)) {
        e.preventDefault();
        if (e.key === 'ArrowUp') {
          setSelectedIndex((selectedIndex + items.length - 1) % items.length);

          return true;
        }
        if (e.key === 'ArrowDown') {
          setSelectedIndex((selectedIndex + 1) % items.length);

          return true;
        }
        if (e.key === 'Enter') {
          handleSelect(selectedIndex);

          return true;
        }

        return false;
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [items, selectedIndex, setSelectedIndex, handleSelect]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  useEffect(() => {
    const container = commandListContainer.current;
    const item = selectedButtonRef.current;

    if (item && container) {
      container.scrollTop = item.offsetTop - container.offsetTop;

      item.focus();
    }

    if (selectedIndex === 0 && items.length > 0) {
      setTimeout(() => {
        selectedButtonRef.current?.focus();
      }, 10);
    }
  }, [selectedIndex, items]);

  return items.length > 0 ? (
    <div
      ref={commandListContainer}
      className='border-border h-auto max-h-[330px] w-72 overflow-hidden scroll-smooth rounded-md border bg-background shadow-md transition-all file:z-50'
    >
      <div className='flex max-h-[330px] flex-col overflow-y-auto px-1 py-2'>
        {items.map(({ icon: Icon, title }: CommandItemProps, index: number) => {
          return (
            <button
              key={index}
              className={cn('flex w-full flex-row items-center gap-2 border-none p-2 outline-none')}
              onClick={() => handleSelect(index)}
            >
              <Icon size={20} />

              <div className={cn(body())}>{title}</div>
            </button>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default CommandList;
